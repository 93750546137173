import "../styles/index.css"; // To fix Router.push() not working issue: https://github.com/vercel/next.js/issues/5203
import * as Sentry from "@sentry/node";

interface ExtendedError extends Error {
  error?: any;
  errorMessages?: any;
  errors?: any;
}

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    enabled: process.env.APP_ENV === "production",
    // ignoreErrors: [
    //   'Non-Error exception captured'
    // ],
    // integrations,
    beforeSend(event, hint) {
      if (!hint.originalException || !hint.syntheticException) return event;
      const customError = hint.originalException as ExtendedError;
      const customErrorMsg = `errorMessages: ${customError.errorMessages}, errors: ${customError.errors}, error: ${customError.error}`;
      console.log("Hing: ", hint);
      if (
        hint.syntheticException.message.startsWith(
          "Non-Error exception captured"
        ) &&
        customErrorMsg
      ) {
        const err: ExtendedError = new Error(customErrorMsg);
        err.name = "customError";
        Sentry.withScope((scope) => {
          scope.setExtra("nonErrorException", true);
          Sentry.captureException(err);
        });
        return null;
      }
      return event;
    },
  });
}

function App({ Component, pageProps, err }) {
  return <Component {...pageProps} err={err} />;
}

export default App;
